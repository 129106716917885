export default {
	1: 0,
	2: 2,
	b3: 3,
	3: 4,
	4: 5,
	b5: 6,
	5: 7,
	'#5': 8,
	b6: 8,
	6: 9,
	bb7: 9,
	b7: 10,
	7: 11,
	b9: 13,
	9: 14,
	'#9': 15,
	11: 17,
	'#11': 18,
	b13: 20,
	13: 21,
};
